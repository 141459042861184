import React, { useState } from 'react';
// Customizable Area Start
import {
    Button,
    Backdrop,
    Menu,
    MenuItem,
    Radio,
    FormControlLabel
} from "@material-ui/core";
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { withTranslation } from 'react-i18next';
// Customizable Area End
class QuoteManagementFilter extends React.Component<IProps, IState> {
    // Customizable Area Start
    constructor(props: IProps) {
        super(props);

        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.onFilterMenuClosed = this.onFilterMenuClosed.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);

        this.state = { filterAnchorEl: null }
    }

    onFilterClicked(event: React.MouseEvent<HTMLElement>) {
        this.setState({
            filterAnchorEl: event.currentTarget as any
        })
    }

    onFilterMenuClosed() {
        this.setState({ filterAnchorEl: null })
    }

    onValueChanged = (value: TFilterValue) => {
        this.onFilterMenuClosed();
        this.props.onFilterValueChanged(value);
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { filterValue ,t} = this.props;

        return (
            <>        
                <Button
                    variant="text"
                    onClick={this.onFilterClicked}
                    className="filter-button"
                    data-testid="filter-button"
                >
                    <span className="button-text">{t("Filter")}</span>
                    <FilterListOutlinedIcon />
                </Button>
    
                <Backdrop open={Boolean(this.state.filterAnchorEl)} style={{ zIndex: 999999999999 }}>
                    <Menu
                        className="filter-menu"
                        data-testid="filter-menu"
                        anchorEl={this.state.filterAnchorEl}
                        keepMounted
                        open={Boolean(this.state.filterAnchorEl)}
                        onClose={this.onFilterMenuClosed}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem data-testid="filter-CONFIRMED" onClick={() => this.onValueChanged('CONFIRMED')}><FormControlLabel label={t("confirmed")} control={<Radio color='primary' checked={filterValue === 'CONFIRMED'} />} /></MenuItem>
                        <MenuItem data-testid="filter-ORDER-SUBMITTED" onClick={() => this.onValueChanged('ORDER_SUBMITTED')}><FormControlLabel label={t("orderSubmitted")} control={<Radio color='primary' checked={filterValue === 'ORDER_SUBMITTED'} />} /></MenuItem>
                        <MenuItem data-testid="filter-PARTIAL-ORDER-SUBMITTED" onClick={() => this.onValueChanged('PARTIAL_ORDER_SUBMITTED')}><FormControlLabel label={t("FullDelivery")} control={<Radio color='primary' checked={filterValue === 'PARTIAL_ORDER_SUBMITTED'} />} /></MenuItem>
                    </Menu>
                </Backdrop>
            </>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
type TFilterValue = 'CONFIRMED' | 'ORDER_SUBMITTED' | 'PARTIAL_ORDER_SUBMITTED';

export interface IProps {
    filterValue: TFilterValue,
    onFilterValueChanged: (value: TFilterValue) => void,
    t?:any
}

interface IState {
    filterAnchorEl: null | HTMLElement
}
export {QuoteManagementFilter}
export default withTranslation()(QuoteManagementFilter);
// Customizable Area End