// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Link
} from "@material-ui/core";
import { IActiveQuoteData } from "./types";
import {
  classNames,
  formatPrice,
} from "../../../components/src/utilities/utils";
import { withTranslation } from "react-i18next";
import Table from "../../../components/src/Table.web";
import DeleteProductBackendDailog from "../../../components/src/DeleteProductBackendDailog";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End
const QuoteDetailsView: React.FC<IProps> = ({
  loading,
  page,
  activeQuote,
  t,
  submitOffer,
  fetchQuoteDetail,
  DeleteQuote,
  isOfferLoading
}) => {
  const [activeOrder, setActiveOrder] = useState([]);
  const [activeQuoteData, setActiveQuoteData] = useState({
    id: '',
    attributes: {
      customer: { data: { attributes: { customer_code: "" } } },
      price:"",
      total_sum:"",
      total_amount: "",
      delivery_type:"",
      address:{data:{id:0,attributes: { 
      address_citya: "",
      address_countya: "",
      address_namea: "",
      address_numbera: "",
      address_postcodea: "",
      address_statea: "",
      address_streeta: "",
      customer_code: "" }}},
      alternate_address:""
    },
  });
const [ catalogueData , setCatalogueData] = useState([]);

  const tableTitles = React.useMemo(() => {
    const ActiveOrdersTableName = [
      "ProductID",
      "ProductName",
      "Sub-Category",
      "Total_Cost",
      "Type",
      "To_Ship",
      "Delivery_Address",
    ];
    if (
      !Array.isArray(activeQuote?.attributes?.order_items?.data) ||
      (Array.isArray(activeQuote?.attributes?.order_items?.data) &&
        activeQuote?.attributes?.order_items?.data === 0)
    ) {
      return ActiveOrdersTableName;
    }

    return ActiveOrdersTableName.map((title) => title);
  }, [activeQuote?.attributes?.order_items?.data]);

  const handleSetStorage = async (dataToStore: {
    products: {};
    prices: {};
    totalPrice: number;
    productDetails: {};
  }) => {
    await getStorageData("cartData") && await setStorageData(`cartData`, JSON.stringify(dataToStore))
  }
  useEffect(() => {
    if (activeQuote?.attributes?.order_items?.data !== undefined) {
      setActiveOrder(activeQuote?.attributes?.order_items?.data);
      const newArray = {
        products: {},
        prices: {},
        totalPrice: 0,
        productDetails: {}
      };
    
    activeQuote?.attributes?.order_items?.data.forEach((item:any) => {
      const productId = item?.attributes?.catalogue?.data?.attributes?.product_id;
      const cartQuantity = item?.attributes?.quantity;
      //@ts-ignore
      newArray.products[`id_${productId}`] = {
        product_id: productId,
        cart_quantity: cartQuantity
      };
      //@ts-ignore
      newArray.productDetails[`id_${productId}`] = item?.attributes?.catalogue?.data?.attributes;
      setCatalogueData(item?.attributes?.catalogue?.data?.attributes)
    });
    
    const dataToStore = {
      products: newArray.products,
      prices: {},
      totalPrice: 0,
      productDetails: newArray.productDetails
    };
    handleSetStorage(dataToStore)
    }
    if (activeQuote?.attributes !== undefined) {
      setActiveQuoteData(activeQuote);
    }
  
  }, [activeQuote?.attributes?.order_items?.data, activeQuote]);
    const url_id = window.location.hash.substring(1)
    const localfun = () => {
      localStorage.setItem('UPDATE',"UPDATE")
      //@ts-ignore
      fetchQuoteDetail(url_id);
    }
    const countNullCatalogue = activeOrder.filter(
      (orderItem:any) => 
        orderItem.attributes.catalogue.data === null
    );
    const notNullCatalogue = activeOrder.filter(
      (orderItem:any) => 
        orderItem.attributes.catalogue.data !== null
    );
    const renderSubmitButton = () => {
      return isOfferLoading ? (
        <CircularProgress color="primary" size={24} />
      ) : (
        <span>{t('SubmitQuote')}</span>
      );
    }
  // Customizable Area End
  return (
    // Customizable Area Start
    <>
    {
      countNullCatalogue?.length >= 1 ? 
      <DeleteProductBackendDailog
      //@ts-ignore
       t={t}
       countNullCatalogue={countNullCatalogue}
       notNullCatalogue={notNullCatalogue}
       activeOrder= {activeOrder}
       onDeleteQuote={() => {
        DeleteQuote(window.location.hash.substring(1))                      
      }} 
       /> : ''
    }
      {loading ? (
        <div data-testid="active-order-loading" className="loading-section">
          <CircularProgress style={{ width: 64, height: 64 }} color="primary" />
        </div>
      ) : (
        <div className="active-order-section">
          <Grid container spacing={3} className="top-section" >
            <Grid item component="div" className="order-details"  md={7}>
            <Typography variant="h1" component="h1" className="page-title">
                  {t('SavedQuotes')}
            </Typography>
              <Typography
                data-testid="order-id-title"
                variant="h1"
                component="h1"
              >
                {t("Cart_ID")} {activeQuoteData?.id}
              </Typography>
              <Paper elevation={0} className="order-section-card">
                <div className="order-detail-item">
                  <Typography component="strong"  variant="subtitle1">
                    {t("Customer_ID")}
                  </Typography>
                  <span data-testid="customer-id">
                    {
                      activeQuoteData.attributes.customer.data.attributes
                        .customer_code
                    }
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong"  variant="subtitle1" >
                    {t("Total_Amount")}
                  </Typography>
                  <span data-testid="total-fees">
                    € {formatPrice(activeQuoteData.attributes.total_sum)}
                  </span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Table
            stickyHeader
            className="orders-products-table"
            titles={tableTitles}
            items={notNullCatalogue?.map((item: any) => {
              const address = `${activeQuoteData?.attributes?.address?.data?.attributes?.address_citya}`+ " " +`${activeQuoteData.attributes?.address?.data?.attributes?.address_numbera}`+" "+`${activeQuoteData?.attributes?.address?.data?.attributes?.address_countya}`+ " " +`${activeQuoteData.attributes?.address?.data?.attributes?.address_statea}`+ " " +`${activeQuoteData?.attributes?.address?.data?.attributes?.address_postcodea}`;
              return {
              product_id:
                item?.attributes?.catalogue?.data?.attributes?.product_id?item?.attributes?.catalogue?.data?.attributes?.product_id:item.attributes.static_product_id,
              product_name: item?.attributes?.catalogue?.data?.attributes?.name?item?.attributes?.catalogue?.data?.attributes?.name:item.attributes.static_product_name,
              sub_category:
                item?.attributes?.catalogue?.data?.attributes?.sub_category?.name? item?.attributes?.catalogue?.data?.attributes?.sub_category?.name:item.attributes.static_sub_category,
                total_Cost: (
                  <div className="price">              
                      <span className="span">€</span><span>{formatPrice(item.attributes.total_cost)}</span>
                  </div>
                ),
                type: activeQuoteData?.attributes.delivery_type === "final_delivery"?"Fully":"Partially",
                to_ship: "Yes",
                Delivery_Address: activeQuoteData?.attributes?.address?.data!== null? address:activeQuoteData?.attributes.alternate_address,
              };
            })}
          />
          <div
            className={classNames({
              "cart-table-footer": true,
              "__is-checkout": page === "SAVED_QUOTE",
            })}
          >
            <strong className="total-cart-amount">
              {t("TotalQuoteAmount")}
            </strong>
            <span className="price" style={{fontWeight : 'bold'}}>
            {
              activeQuoteData.attributes.total_amount === null?<>
              <span className="span"> € </span>
              <span>{formatPrice(activeQuoteData.attributes.price)}
              </span>
              </>
              :
              <>
              <span className="span" > € </span>
              <span >{formatPrice(activeQuoteData.attributes.total_sum)}
              </span>
              </>
            }                        
            </span>
          </div>
          <div className="button-group">
            <>
            <Link href={`/Quotemanagement#${url_id}`}>
              <Button variant="outlined" color="primary"disabled={catalogueData === undefined && activeOrder.length === 1 || notNullCatalogue.length ===0} onClick={()=>{localfun()}} fullWidth >
                {t("EditQuote")}
              </Button>
            </Link>
            {
             catalogueData === undefined && activeOrder.length === 1 || notNullCatalogue.length === 0 ? 
             <Button variant="outlined" color="primary" disabled fullWidth>
                {t("ConfirmedQuotes")}
              </Button>:
            <Button
            data-test-id='submit-order-button-view'
            variant="outlined"
            disabled={isOfferLoading}
            color="primary"
            onClick={() => {submitOffer("")}}
        >
           {renderSubmitButton()}
        </Button>
            }
          <Link href={`/Quotemanagement#${url_id}#checkout`}>
          <Button data-test-id = "placeholder-btn" variant="contained" color="primary"  fullWidth onClick={localfun} >
          {t("SubmitOrder")}
          </Button>
        </Link>
         
            </>
          </div>
        </div>
      )}
    </>

    // Customizable Area End
  );
};

// Customizable Area Start

interface IProps {
  page: "SAVED_QUOTE" | "CONFIRMED_QUOTE";
  activeQuote: IActiveQuoteData;
  t: any;
  loading: boolean;
  id: any;
  submitOrder?: (addressId: string, alternateAddress?: string) => void;
  view: "LIST" | "DETAILS" | "CHECKOUT";
  createNewAddress: (address: string) => void;
  submitOrderDialogClose: any;
  navigateToOrderDetails: any;
  createdOrderDetails: any;
  addresses: any;
  newAddressLoading: boolean;
  newAddressSubmitted: boolean;
  isOrderLoading: boolean;
  isOrderSubmitted: boolean;
  quantityUpdate: any;
  changeOrderMethod: (method: any) => void;
  orderMethod?: 'PARTIAL_DELIVERY' | 'FINAL_DELIVERY_ONLY',
  submitOffer:(addressId: string, alternateAddress?: string, deliveryType?:string) => void;
  fetchQuoteDetail?:(quote_id: any)=>void;
  DeleteQuote:(quote_id:any)=>void;
  isOfferLoading:boolean;
  // isOfferSubmitted:boolean
}

interface IState {}

export default withTranslation()(QuoteDetailsView);
// Customizable Area End
